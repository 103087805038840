function NavigationBar() {
  return(
  <div className="nav">
    <div className="navcontent">
      <ul>
        <li><a href='#About'>about</a></li>
        <li><a href='#Gigs'>gigs</a></li>
        <li><a href='#Music'>music</a></li>
        <li><a rel="noreferrer" target="_blank" href="https://morvigor.bandcamp.com/">merch</a></li>
      </ul>
    </div>
  </div>
  );
};

export default NavigationBar;
