import bandPhoto from './images/morvigor.jpg';
function About() {
  const split = '//'; return(
    <div id="About">
      <h1>MORVIGOR</h1>
      <div id="AboutContainer">
      Dutch four-piece, Morvigor, are emerging from a period of metamorphosis with renewed purpose and a clear vision for their future. Embodying expressive 
      black metal, they have an unorthodox approach to the genre that feels visceral and vital. Morvigor embraces cinematic ambience as they sweep through 
      progressive and post-metal territory, with cryptic lyrics that touch on existentialism and philosophical thought, creating a distinctive sound all of 
      their own.

        <p>
          <table id="memberTable">
            <tr><td>Evio Paauw</td><td>-</td><td>Bass, vocals, lyrics</td></tr>
            <tr><td>Sytze Andringa</td><td>-</td><td>Guitar, clean vocals</td></tr>
            <tr><td>Stefan van Delft</td><td>-</td><td>Guitar</td></tr>
            <tr><td>Brendan Duffy</td><td>-</td><td>Drums</td></tr>
          </table>
        </p>
        To contact us, for bookings or other questions, you can send an e-mail to info@morvigor.nl

		  </div>
      <p style={{fontSize: "smaller"}}>
        <a rel="noreferrer" target="_blank" className="socialLinks" href="https://www.instagram.com/morvigor/"><i> instagram </i></a>
        { split }
        <a rel="noreferrer" target="_blank" className="socialLinks" href="https://www.youtube.com/@Morvigor"><i> youtube </i></a>
        { split }
        <a rel="noreferrer" target="_blank" className="socialLinks" href="https://www.facebook.com/Morvigor"><i> facebook </i></a>
        { split }
        <a rel="noreferrer" target="_blank" className="socialLinks" href="https://open.spotify.com/artist/6havBNPBvuwfYDeezBjice"><i> spotify </i></a>
        { split }
        <a rel="noreferrer" target="_blank" className="socialLinks" href="https://morvigor.bandcamp.com"><i> bandcamp </i></a>
      </p>

      <div className="BandDiv">
        <img
          src={bandPhoto}
          /* width="40%" */
          className="BandPhoto"
          style={{marginBottom: '-5px'}}
          alt="morvigor"
        />
      </div>
    </div>

  );
};

export default About;
