
export const sort = (list) => {
  return list.sort((a, b) => a.date - b.date);
};

export const doReverseDate = (date) => date.split("-").reverse().join("-");

export const reverseDate = (entities) => {
  return entities.map((entity) => {
    return {
      ...entity,
      date: doReverseDate(entity.date),
    }
  })
};

export const parseStringToDate = (entities) => {
  return entities.map((entity) => {
    return {
      ...entity,
      date: new Date(entity.date),
    }
  })
};

export const getGigsToShow = (gigs) => {
  // determines for how many days the gigs are shown after the date has passed 
  const daysMargin = 365;
  const referenceDay = new Date() -  daysMargin * 24*60*60*1000;
  console.log(referenceDay);
  return gigs.filter((gig) => {
    return gig.date >= referenceDay;
  })
};

export const parseDateToString = (date) => {
  return date.toLocaleDateString('nl-NL', {year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
};
