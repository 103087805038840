import React, { useEffect, useState } from "react";
import { getConfig } from "./helpers/config";
import Review from './Review.js';

function split(list) {
  const middle = Math.floor(list.length/2);
  const left = list.slice(0, middle);
  const right = list.slice(middle);
  return [left, right];
}

function Reviews({album}) {
  const [ reviews, setReviews ] = useState({});
  const hideOnStart = false
  const [ hide, setHide ] = useState(hideOnStart);
  const handleOnClick = () => setHide(!hide);

  useEffect(() => {
    const fetchConfig = async () => {
        const config = await getConfig('reviews.json');
        setReviews(config);
    }
    fetchConfig();
  }, []);

  const albumReviews = reviews[album.label] || [];
  const reviewsToShow = albumReviews.filter(({hide}) => !hide);
  const arrow = hide ? '▼' : '▲';
  const hasReviews = albumReviews.length > 0;

  let left, right = []
  if(hasReviews){
    [left, right] = split(reviewsToShow);
  };

  return (
    <div className="reviewContainer" style={{ color: '#858585'}}>
      {hasReviews &&
    <h3
      onClick={() => handleOnClick()}
      className="showMore"
      style={{
        textAlign: 'left',
      }}>
        show reviews
      <p
        style={{
          color: '#777777',
          display: 'inline',
          marginLeft: '1%',
          fontSize: '0.7em'
        }}>  {arrow}
      </p>
    </h3>
      }
    {!hide && hasReviews &&
     <>
    <div class="leftContent">
        {left.map((r) => (
          <Review review={r}/>
        ))}
    </div>
    <div class="rightContent">
        {right.map((r) => (
          <Review review={r}/>
        ))}
    </div>
    <h5
      style={{
        marginTop: '50px',
        cursor: 'pointer',
        textAlign: 'center'
      }}
      onClick={() => handleOnClick()}>

      --Hide--

    </h5>
     </>
    }
    </div>
  );
}

export default Reviews;
